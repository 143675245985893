import React from "react"

import { ImageGrid } from "../ImageGrid.tsx"
import { Hero } from "../Hero.tsx"
import { TextColumns } from "../TextColumns.tsx"
import { StyledFlex } from "../StyledFlex.tsx"
import { Image } from "../Image.tsx"

const B3 = number => `WhiteB3-${number}.jpg`

export const Mist03Section = () => {
  return (
    <>
      <Hero>
        Mist 03
        <Image src={"WhiteB3-Thumbnail"} />
      </Hero>
      <TextColumns offset>
        <div></div>
        <div>9/10/19 - 11/5/19</div>
        <div>
          Lodge 03 was inspired by the angular aspects within wild-style
          graffiti. This style of artwork correlates to Lebbeus Woods’s or Zaha
          Hadid’s hand renderings. Lebbeus Woods has been a big inspiration with
          how I design and attempt to think in terms of an imaginative world. I
          try to expand my outlook on what architecture is vs what it could be.
          I create study models and sketches for quick design iterations that
          allow me to free form and work with my hands. This study model was
          crafted after gathering inspiration from various forms including
          surrounding objects, various drawing details, and textures that I felt
          might work. When analyzing these models, I tended to find myself
          looking eye level with the model. I tried to imagine the overall form
          from a human’s perspective instead of relying solely on a plan or
          section view.
        </div>
      </TextColumns>

      <Image src={B3(1)} />

      <ImageGrid>
        <Image src={B3(12)} />
        <Image src={B3(9)} />
        <Image src={B3(3)} />
        <StyledFlex align={"flex-end"}>
          <StyledFlex />
          <StyledFlex>
            The most prominent feature is the paneling on the side and on top of
            the building. This device is intended to open and close during
            certain times of the day and year. This allows for natural lighting
            and ventilation to play a huge role. The side paneling allows for a
            diamond shape pattern to be displayed on the floor and travel up the
            opposing wall.
          </StyledFlex>
        </StyledFlex>
      </ImageGrid>

      <Image src={B3(2)} />
      <StyledFlex align={"flex-end"}>
        <StyledFlex>
          <p>
            The contrast of the building and its surrounding context allows for
            it to seem alienated. To help create a natural transition, the
            entrance of the building is composed of a deconstructing shell. As
            the length progresses, the height and texture begin to decrease.
          </p>
        </StyledFlex>
        <StyledFlex />
      </StyledFlex>

      <ImageGrid>
        <Image src={B3(14)} />
        <Image src={B3(13)} />
        <StyledFlex align={"flex-end"}>
          <StyledFlex />
          <StyledFlex>
            The interior space is tilted off center to allow for an interrupted
            view and create a more visually compelling building. This did
            present complications with surrounding functions. The shifting of
            the building did not allow for adequate head space to the stairs.
            The cut out of the lodge allowed for the needed room and an
            interesting bedding area. Another complication was the door swing in
            such close quarters. Thus, the door was designed to move in a
            vertical motion.
          </StyledFlex>
        </StyledFlex>
      </ImageGrid>

      <Image src={B3(11)} />

      <ImageGrid>
        <Image src={B3(15)} />
        <Image src={B3(16)} />
        <Image src={B3(17)} />
        <Image src={B3(18)} />
      </ImageGrid>

      <Image src={B3(10)} />

      <div className={"drawingsHeader"}>Process</div>

      <Image src={B3(4)} />

      <ImageGrid>
        <Image src={B3(5)} />
        <Image src={B3(6)} />
        <Image src={B3(7)} />
        <Image src={B3(8)} />
      </ImageGrid>
    </>
  )
}
