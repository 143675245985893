import React from "react"

import { ImageGrid } from "../ImageGrid.tsx"
import { Hero } from "../Hero.tsx"
import { TextColumns } from "../TextColumns.tsx"
import { StyledFlex } from "../StyledFlex.tsx"
import { Image } from "../Image.tsx"

const B5 = number => `WhiteB5-${number}.jpg`

export const Mist05Section = () => {
  return (
    <>
      <Hero>
        Mist 05
        <Image src={"WhiteB5-Thumbnail"} />
      </Hero>
      <TextColumns offset>
        <div></div>
        <div>9/10/19 - 11/5/19</div>
        <div>
          Lodge 05 is located inside of a gyroscope. The sphere’s spacial
          qualities are meant to invoke an interesting feeling in the resident.
          Within the lodge there is only one viewport. The gyroscope rotates
          throughout the day capturing sunlight and framing key elements. The
          lodge is set on the rivers bank, shrouded in the shadows of the trees.
          This allows the building to blend in and allow nature to be
          undisturbed. The foundation design was inspired by a drainage system.
          During the different seasons, the water level rises and lowers and
          allows for organic elements to fall and get caught. The constant
          moisture creates the perfect climate for moss and other vegetation to
          grow within the system. This helps clean up the river while providing
          an additional visual effect as the concrete is stained with a variety
          of colors. An individual walkway is included to offer an option to
          meditate indoors or outdoors while having an overhead view of the
          gutter. The supporting cables are meant to seem hidden within the tree
          foliage and gather vines overtime.
        </div>
      </TextColumns>

      <Image src={B5(1)} />
      <ImageGrid>
        <Image src={B5(2)} />
        <Image src={B5(4)} />
      </ImageGrid>

      <Image src={B5(3)} />
    </>
  )
}
