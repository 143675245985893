import React from "react"

import { ImageGrid } from "../ImageGrid.tsx"
import { Hero } from "../Hero.tsx"
import { TextColumns } from "../TextColumns.tsx"
import { StyledFlex } from "../StyledFlex.tsx"
import { Image } from "../Image.tsx"

const B2 = number => `WhiteB2-${number}.jpg`

export const Mist02Section = () => {
  return (
    <>
      <Hero>
        Mist 02
        <Image src={"WhiteB2-Thumbnail"} />
      </Hero>
      <TextColumns offset>
        <div></div>
        <div>9/10/19 - 11/5/19</div>
        <div>
          Lodge 02’s significance is the use of sunlight and shadows. The
          structural elements involved are meant to cast interesting shadows
          throughout the space and allow residents to elect positions in either
          shade or sunlight. The only limit to the provoked experience is the
          resident’s imagination.
        </div>
      </TextColumns>

      <Image src={B2(9)} />
      <StyledFlex>
        <StyledFlex>
          <p>
            The extruding wall that jolts out is composed into 2 parts. The
            cladded facade is meant to block out the incoming sunlight during
            the evening, but has window hatches to allow for sunlight
            penetration. The cladded facade is meant to block out incoming
            sunlight during the evening, with window hatches to allow residents
            to allow for sunlight penetration or increased ventilation.
          </p>
        </StyledFlex>
        <StyledFlex />
      </StyledFlex>

      <ImageGrid>
        <Image src={B2(2)} />
        <Image src={B2(3)} />
        <StyledFlex align={"flex-end"}>
          <StyledFlex>
            The facade has a slight curvature near the base to allow for a
            backrest but extends out for the placement of a yoga mat. The old
            car garage has a curve in the opposite direction to offer a sense of
            enclosure.
          </StyledFlex>
          <StyledFlex />
        </StyledFlex>
        <Image src={B2(4)} />
      </ImageGrid>

      <Image src={B2(6)} />
      <StyledFlex align={"flex-end"}>
        <StyledFlex>
          <p>
            Lodge 02’s inspiration came from a satellite dish, which proved to
            be an interesting design source. Rather than pulling information
            from built projects, trying to revision unrelated objects allowed
            inadvertent ideas.
          </p>
        </StyledFlex>
        <StyledFlex />
      </StyledFlex>

      <ImageGrid>
        <Image src={B2(8)} />
        <Image src={B2(7)} />
        <StyledFlex>
          <StyledFlex>
            The tension wires holding the tree up and the wires around the
            lodging’s interior space are meant to be open for natural growth.
            Within the interior space, the vegetation will act as a filter for
            light and allow interesting shadows to move. It also provides shade
            for the interior space. The majority of the interior should be made
            out of glass to allow view of the vegetation.
          </StyledFlex>
          <StyledFlex />
        </StyledFlex>

        <StyledFlex>
          <StyledFlex>
            The suspension of the tree is intended to be unorthodox. Instead of
            placing trees around the building, I wanted to allow the building to
            be placed around the tree. This influences the perspective of the
            tree and allows the trunk to be observed at odd angles. For
            practical purposes, the tree’s base has a built-in irrigation
            system.
          </StyledFlex>
          <StyledFlex />
        </StyledFlex>
      </ImageGrid>

      <Image src={B2(5)} />
      <StyledFlex align={"flex-end"}>
        <StyledFlex>
          <p>
            The materiality of the building is meant to change over time. The
            use of welded steel, old car garages, and tension wires allows for
            the elements to tell a story as they oxidize and rust over time.
            Additionally, this disintegration creates a synergy between the
            natural landscape and hard-edged building as both succumb to natural
            forces over time.
          </p>
        </StyledFlex>
        <StyledFlex />
      </StyledFlex>

      <ImageGrid>
        <Image src={B2(10)} />
        <Image src={B2(11)} />
      </ImageGrid>
    </>
  )
}
