import React from "react"

import { ImageGrid } from "../ImageGrid.tsx"
import { Hero } from "../Hero.tsx"
import { TextColumns } from "../TextColumns.tsx"
import { StyledFlex } from "../StyledFlex.tsx"
import { Image } from "../Image.tsx"

const B1 = number => `WhiteB1-${number}.jpg`

export const Mist01Section = () => {
  return (
    <>
      <Hero>
        Models
        <Image src={"models-thumbnail"} />
      </Hero>

      <TextColumns offset>
        <div></div>
        <div>9/10/19 - 11/5/19</div>
        <div>
          Project Mist is a series of distinct lodges designed for a yoga
          retreat. The site is located near a riparian area that contains a high
          amount of vegetation. Each lodge is unique, with diverse elements and
          unorthodox designs meant to accentuate the experience of the
          residents. The spaces are designed to allow residents to meditate as
          well as practice the material they learn in classes throughout the
          day.
        </div>
      </TextColumns>

      <ImageGrid>
        <Image src={B1(1)} />
        <Image src={B1(2)} />
        <Image src={B1(3)} />
        <StyledFlex align="flex-end">
          <StyledFlex>About</StyledFlex>
          <StyledFlex>
            Lodge 01 includes a 5-foot spacial element meant for the residents
            to take showers in a semi-enclosed environment surrounded by nature.
            The large windows on opposite ends of the enclosed space build a
            relationship between confinement and openness. Meditation can take
            place inside or outside of the building.
          </StyledFlex>
        </StyledFlex>
      </ImageGrid>

      <Image src={"WhiteB1-Sketch1"} />
      <p>Hand drawing depicting a midnight scene.</p>

      <TextColumns>
        <div>
          The spacial element is intended to direct the user’s experience. The
          idea of having a compact space creates a design challenge to balance
          storage needs, sleeping space, and claustrophobia for residents. The
          storage compartments are placed in the connection between the two
          linear elements. The goal of the yoga retreat informs the small size
          of the compartments. The removal of the outside world allows one to
          focus on what items are necessary and disregard ones that are a
          distraction, so it is expected that residents entering the experience
          would already be carrying few possessions.
        </div>
        <div>
          The sleeping arrangement was inspired by Japanese tatami and futon.
          Tatami is a flooring used in traditional Japanese-style rooms. A futon
          is a Japanese style of bedding consisting of a mattress, quilt, and
          pillow. These design inspirations allow for a flexible interior space.
          One can practice yoga elements learned in class and then unroll the
          futon to sleep.
        </div>
        <div>
          Claustrophobia was combated with a floor to ceiling window. The window
          is doubly important to introduce transparency in an otherwise solid
          space.The overall space is intended to be unconventional and allow for
          the occupant to experience different spaces. The idea is that a normal
          hotel room would create a disconnect from the retreat and not allow
          for complete immersion in yoga.
        </div>
      </TextColumns>

      <ImageGrid>
        <Image src={B1(4)} />
        <Image src={B1(10)} />
        <Image src={B1(8)} />
        <StyledFlex align="flex-end">
          <StyledFlex />
          <StyledFlex>
            The extruding form on the right side allows ground level residents
            to sit and appreciate the surrounding area. From a design
            perspective, this evens out the form to create a balanced look. This
            feature also helps support the load of the walkway and interior
            space.
          </StyledFlex>
        </StyledFlex>
      </ImageGrid>

      <Image src={B1(11)} />
      <p>Building 01 aerial view.</p>

      <Image src={B1(6)} />
      <StyledFlex align="flex-end">
        <StyledFlex>
          <p>
            The entrance to the building shares a staircase with the walkway
            that allows for circulation throughout the lodge. The weight of the
            stairs is cantilevered off the stringer. This allows for a floating
            staircase. A stairway awning allows for protection from the elements
            and the capture of fallen leaves above. Creating a living picture.
          </p>
        </StyledFlex>
        <StyledFlex />
      </StyledFlex>

      <ImageGrid>
        <Image src={B1(9)} />
        <Image src={B1(5)} />
        <Image src={B1(12)} />
        The use of different textures in the building creates visual interest.
      </ImageGrid>
    </>
  )
}
