import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ImageGrid } from "../components/ImageGrid"
import { Hero } from "../components/Hero"
import { TextColumns } from "../components/TextColumns"
import { StyledFlex } from "../components/StyledFlex"
import { Image } from "../components/Image"

import { Mist01Section } from "../components/sections/Mist01Section"
import { Mist02Section } from "../components/sections/Mist02Section"
import { Mist03Section } from "../components/sections/Mist03Section"
import { Mist04Section } from "../components/sections/Mist04Section"
import { Mist05Section } from "../components/sections/Mist05Section"
import { ConcreteFogSection } from "../components/sections/ConcreteFogSection"
import { SketchSection } from "../components/sections/SketchSection"

const ModelsPage = () => {
  return (
    <Layout isProjectPage>
      <SEO />

      <Mist01Section />
      <Mist02Section />
      <Mist03Section />
      <Mist04Section />
      <Mist05Section />
      <ConcreteFogSection />
    </Layout>
  )
}

export default ModelsPage
