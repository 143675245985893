import React from "react"

import { ImageGrid } from "../ImageGrid.tsx"
import { Hero } from "../Hero.tsx"
import { TextColumns } from "../TextColumns.tsx"
import { StyledFlex } from "../StyledFlex.tsx"
import { Image } from "../Image.tsx"

const B4 = number => `WhiteB4-${number}.jpg`

export const Mist04Section = () => {
  return (
    <>
      <Hero>
        Mist 04
        <Image src={"WhiteB4-Thumbnail"} />
      </Hero>
      <TextColumns offset>
        <div></div>
        <div>9/10/19 - 11/5/19</div>
        <div>
          Lodge 04 is centralized around elevation to intrigue the resident with
          a different perspective of the surrounding environment. The elevated
          space allows for significant spacial qualities. The building is
          located on a gentle sloping hill to allow a height advantage when
          looking out. The two spaces, the living quarters and meditation space,
          allow for different perspectives. The lower level floor is in the
          canopy line which allows for glimpses of the forest floor, while the
          top space is above the tree line to allow for a panoramic view. This
          permits both sunrise and sunset yoga experiences.
        </div>
      </TextColumns>

      <ImageGrid>
        <Image src={B4(2)} />
        <Image src={B4(4)} />
        <StyledFlex>
          <StyledFlex>
            The circulation of the building is based off external stairs. This
            transitional space allows for continuous circulation and zero
            interruptions within the surrounding space.
          </StyledFlex>
          <StyledFlex />
        </StyledFlex>
      </ImageGrid>

      <Image src={B4(3)} />
      <StyledFlex>
        <StyledFlex>
          <p>
            The banding around the floor plates has a texture that casts
            interesting shadows during the day. By contrast, at night it serves
            as recessed lighting to illuminate the surrounding areas.
          </p>
        </StyledFlex>
        <StyledFlex />
      </StyledFlex>

      <Image src={B4(5)} />
      <StyledFlex>
        <StyledFlex>
          <p>
            The structural members that carry the floor load follow the
            topography and allow for a more expressive feel.
          </p>
        </StyledFlex>
        <StyledFlex />
      </StyledFlex>
    </>
  )
}
